import React from "react";

import { Chart as ChartJS, ArcElement, Tooltip, Legend,CategoryScale,LinearScale,BarElement, Title } from 'chart.js/auto';

import { Doughnut } from 'react-chartjs-2';

ChartJS.register(ArcElement, Tooltip, Legend);

export default function DoughnutChart(chartPassedData : any) {

  return (
    <div>
      <Doughnut options={chartPassedData.chartPassedData.options} data={chartPassedData.chartPassedData.data}  />
    </div>
    )
}