
var $testenv = ['dev.korrectonline.com', 'localhost'];

var $location = window.location;

export var apiUrlPrefix = $location.protocol + '/Api/';

if ($testenv.indexOf($location.hostname) !== -1) {
    //apiUrlPrefix = 'http://test.korrect.yougem.com/Api/';
   //apiUrlPrefix = 'http://staging.onkorrect.com/Api/';
   
   apiUrlPrefix = 'https://belldistro.onkorrect.com/Api/';
   // apiUrlPrefix = 'http://staging1.onkorrect.com/Api/';
    //apiUrlPrefix = 'http://staging2.onkorrect.com/Api/';
    //apiUrlPrefix = 'http://staging3.onkorrect.com/Api/';
    //apiUrlPrefix = 'http://staging4.onkorrect.com/Api/';
    // apiUrlPrefix = 'http://qa.onkorrect.com/Api/';
    //apiUrlPrefix = 'http://korrect.onkorrect.com/Api/';
    //apiUrlPrefix = 'http://fixt.onkorrect.com/Api/';
    //apiUrlPrefix = 'http://dev.korrectonline.com/Api/';
    //apiUrlPrefix = 'http://mtheory.onkorrect.com/Api/';
    //apiUrlPrefix = 'https://lne.onkorrect.com/Api/';
    //apiUrlPrefix = 'https://ninja.onkorrect.com/Api/';
}