import React from "react";

import { Chart as ChartJS, ArcElement, Tooltip, Legend,CategoryScale,LinearScale,BarElement, Title } from 'chart.js/auto';

import { Bar } from 'react-chartjs-2';

ChartJS.register(ArcElement, Tooltip, Legend);

export default function DoughnutChart(chartPassedData : any) {

  return (
    <div>
      <Bar options={chartPassedData.chartPassedData.options} data={chartPassedData.chartPassedData.data}  />
    </div>
    )
}

/*

import React from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

export const options = {
  indexAxis: 'y' as const,
  elements: {
    bar: {
      borderWidth: 0,
    },
  },
  responsive: true,
  plugins: {
    legend: {
      position: 'right' as const,
    },
    title: {
      display: false,
      text: '',
    },
    padding : '300%',
  },
  scales: {
    y:
      {
        min: -15,
        max: 15,
        stepSize: 5,
      },
    x:
      {
        
      },
  },
};

const labels = ['January', 'February', 'March'];

export const data = {
  labels,
  datasets: [
    {
      label: 'Dataset 1',
      data: [15, 6, 7, 1],
      backgroundColor: ['rgba(255, 99, 132, 1)', 'rgba(54, 162, 235, 1)', 'rgba(255, 206, 86, 1)', 'rgba(255, 99, 132, 1)'],
    }
  ],
};

export default function App() {
  return <Bar options={options} data={data} />;
}*/
