import React from "react";

import { Chart as ChartJS, ArcElement, Tooltip, Legend,CategoryScale,LinearScale,BarElement, Title } from 'chart.js/auto';

import { Bubble } from 'react-chartjs-2';

ChartJS.register(ArcElement, Tooltip, Legend);

export default function BubbleChart(chartPassedData : any) {

  return (
    <div>
      <Bubble options={chartPassedData.chartPassedData.options} data={chartPassedData.chartPassedData.data}  />
    </div>
    )
}

/*import React from 'react';
import {
  Chart as ChartJS,
  LinearScale,
  PointElement,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bubble } from 'react-chartjs-3';

ChartJS.register(LinearScale, PointElement, Tooltip, Legend);

export const options = {
  scales: {
    y: {
      beginAtZero: true,
    },
  },
};

export const data = {
  datasets: [
    {
      label: 'Red dataset',
      data: Array.from({ length: 50 }, () => ({
        x: { min: -100, max: 100 },
        y: { min: -100, max: 100 },
        r: { min: 5, max: 20 },
      })),
      backgroundColor: 'rgba(255, 99, 132, 0.5)',
    },
    {
      label: 'Blue dataset',
      data: Array.from({ length: 50 }, () => ({
        x: { min: -100, max: 100 },
        y: { min: -100, max: 100 },
        r: { min: 5, max: 20 },
      })),
      backgroundColor: 'rgba(53, 162, 235, 0.5)',
    },
  ],
};

export default function App() {
  return <div>ggg</div>;
}




/*
import React from 'react';
import {
  Chart as ChartJS,
  LinearScale,
  PointElement,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bubble } from 'react-chartjs-2';

ChartJS.register(LinearScale, PointElement, Tooltip, Legend);

export const options = {
  scales: {
    y: {
      beginAtZero: true,
    },
  },
};



export const data = {
  datasets: [
    {
      label: 'Dataset 2',
      data: Array.from({ length: 2 }, () => ({
        x: [12, 1, 5, 6, 7, 1, 9,13],
        y: [2, 1, 5, 6, 7, 1, 9,13],
        r: [5, 1, 5, 6, 7, 1, 9,13],
      })),
      backgroundColor: '#00ff00',
      borderColor: '#00ff00'
    }, {
      label: 'Dataset 3',
      data: Array.from({ length: 2 }, () => ({
        x: [18, 1, 5, 6, 7, 1, 9,13],
        y: [12, 1, 5, 6, 7, 1, 9,13],
        r: [1, 1, 5, 6, 7, 1, 9,7],
      })),
      backgroundColor: '#0000ff',
      borderColor: '#0000ff'
    }
  ],
};

export default function App() {
  return <Bubble options={options} data={data} />;
}

export default function App() {
  return <></>;
}*/