import React from "react";

import { Chart as ChartJS, ArcElement, Tooltip, Legend,CategoryScale,LinearScale,BarElement, Title } from 'chart.js/auto';

import { Line } from 'react-chartjs-2';

ChartJS.register(ArcElement, Tooltip, Legend);

export default function LineChart(chartPassedData : any) {

  return (
    <div>
      <Line options={chartPassedData.chartPassedData.options} data={chartPassedData.chartPassedData.data}  />
    </div>
    )
}


/*
import React from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import faker from '@faker-js/faker';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);


export const options = {
  responsive: true,
  plugins: {
    legend: {
      position: 'top' as const,
    },
    title: {
      display: true,
      text: 'Chart.js Line Chart',
    },
  },
};

const labels = ['January', 'February', 'March', 'April', 'May', 'June', 'July'];

export const data = {
  labels,
  datasets: [
    {
      label: 'Dataset 1',
      data: [15, 6, 7, 1, 8, 25, 0, 7],
      borderColor: 'rgba(255, 99, 132, 1)',
    },
    {
      label: 'Dataset 2',
      data: [13, 2, 1, 5, 6, 7, 1, 9],
      borderColor: 'rgba(54, 162, 235, 1)',
    },
    {
      label: 'Dataset 3',
      data: [2, 1, 5, 6, 7, 1, 9,13],
      borderColor: 'rgba(255, 206, 86, 1)',
    },
  ],

};

export default function App() {
  return <Line options={options} data={data} />;
}*/