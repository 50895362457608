import React, { Suspense, useState, useEffect } from "react";


import AreaChart from "../Charts/AreaChart";
import BubbleChart from 'components/Charts/BubbleChart';
import DoughnutChart from "../Charts/DoughnutChart";
import HorizontalBarChart from "../Charts/HorizontalBarChart";
import LineChart from "../Charts/LineChart";
import PieChart from "../Charts/PieChart";
import VerticalBarChart from 'components/Charts/VerticalBarChart';



import directoryServices from "./directoryServices";
import { CommonUtilities } from "commonUtils";



export default function Fraudulent(param: any) {

    let [activeMenu, setActiveMenu] = useState("Fraudulent");
    let [financialPerformance, setFinancialPerformance] = useState<any[]>([]);

    

    const onChangeMenu = (e: any) => {
        setActiveMenu(e)
        param.onChangeMenu(e)
    }
/*
var data =[
        {
          "projectCode": "M00004_PROJ",
          "totalProjectContract": 10,
          "countContractStatus": 4,
          "statusPercentage": 40,
          "statusName": "Approved"
        },
        {
          "projectCode": "M00004_PROJ",
          "totalProjectContract": 10,
          "countContractStatus": 0,
          "statusPercentage": 0,
          "statusName": "Closed"
        },
        {
          "projectCode": "M00004_PROJ",
          "totalProjectContract": 10,
          "countContractStatus": 2,
          "statusPercentage": 20,
          "statusName": "Completed"
        },
        {
          "projectCode": "M00004_PROJ",
          "totalProjectContract": 10,
          "countContractStatus": 0,
          "statusPercentage": 0,
          "statusName": "Hold/Suspended"
        },
        {
          "projectCode": "M00004_PROJ",
          "totalProjectContract": 10,
          "countContractStatus": 1,
          "statusPercentage": 10,
          "statusName": "Not Payable – Ctrl Acc"
        },
        {
          "projectCode": "M00004_PROJ",
          "totalProjectContract": 10,
          "countContractStatus": 2,
          "statusPercentage": 20,
          "statusName": "Pending"
        },
        {
          "projectCode": "M00004_PROJ",
          "totalProjectContract": 10,
          "countContractStatus": 1,
          "statusPercentage": 10,
          "statusName": "Revised"
        }
    ];

*/
    

const LandingListing = () => {


                directoryServices.financialPerformance({}).then((res) => {
                    
                    setFinancialPerformance( res );
                    CommonUtilities.relativeDataRemapping(res.data, res.headers.original);
                    
                    //const t = []; //CommonUtilities.relativeDataRemapping(res.data, res.headers.original);
                    console.log(CommonUtilities.relativeDataRemapping(res.data, res.headers.original));
                    setFinancialPerformance( CommonUtilities.relativeDataRemapping(res.data, res.headers.original) );
                })

    }

    
        useEffect(() => {

            LandingListing();
    
        }, [])



    const CallChart = (data : any) => {

        var backgroundColor : any = [];
        var borderColor : any = [];
        var percentageData : any = [];
        var labelsData : any = [];
    
    
        for (var i = 0; i < data.length; i++) {
            percentageData.push(data[i].value);
            labelsData.push(data[i].label);
            
            for (var j = 0; j < CommonUtilities.chartColors.length; j++) {
                backgroundColor.push(CommonUtilities.chartColors[j]);
            }

        }
    
        return CommonUtilities.generateChartData( percentageData, labelsData, backgroundColor, borderColor, 'Status', true );
        
    }





    return (

        <>
            <div className="landing ws-12">


                <div className="ws-12">

                    <div className="box-header-main">
                        <h2>Music Directories</h2>
                    </div>

                    <div className="ws-2 box mtxl pm">
                        <ul className="main-menu">
                            <a className="menu-header">Music Directories</a>
                            <ul>
                                {
                                    directoryServices.directoryMenu?.map((value: any) => {
                                        return (
                                            <>
                                                {(value.route != 'accounts') && <li key={value.title} className={activeMenu == value.title ? "active" : ""}>
                                                    <a className={"ws-12 "} href={"#/" + value.route} onClick={() => { onChangeMenu(value.title) }}> {value.title} </a>
                                                </li>}
                                            </>
                                        )
                                    })
                                }
                            </ul>
                            <a className="menu-header">Data Directories</a>

                            <ul>
                                {
                                    directoryServices.directoryMenu?.map((value: any) => {
                                        return (
                                            <>
                                                {(value.route == 'accounts') && <li key={value.title} className={activeMenu == value.title ? "pull-left ws-12 active" : "pull-left ws-12 "}>
                                                    <a className={"ws-12 "} href={"#/" + value.route} onClick={() => { onChangeMenu(value.title) }}> {value.title} </a>
                                                </li>}
                                            </>
                                        )
                                    })
                                }

                            </ul>
                        </ul>

                        <div className="main-menu-span m0 box ws-12 fullHeight hide">
                            <div className="sidebar-nav ws-12">
                                <ul className="nav nav-stacked main-menu m0 ws-12" id="mainMenu">
                                    <li key="MusicDirectories" className="mtl ws-12 pull-left p0" style={{ background: "none", border: "none" }}>
                                        <a className="nav-header full-width text-bold text-average "><i className="fa fa-music"></i> Music Directories</a>

                                        <ul className="nav nav-tabs full-width">
                                            {
                                                directoryServices.directoryMenu?.map((value: any) => {
                                                    return (
                                                        <>
                                                            {(value.route != 'accounts') && <li key={value.title} className={activeMenu == value.title ? "pull-left ws-12 active" : "pull-left ws-12 "}>
                                                                <a className={"ws-12 "} href={"#" + value.route} onClick={() => onChangeMenu(value.title)}> {value.title} </a>
                                                            </li>}
                                                        </>
                                                    )
                                                })
                                            }

                                        </ul>
                                    </li>

                                    <li key="DataDirectories" className="mtl ws-12 pull-left p0" style={{ background: "none", border: "none" }}>
                                        <a className="nav-header full-width text-bold text-average "><i className="fa fa-music"></i> Data Directories</a>

                                        <ul className="nav nav-tabs full-width">
                                            {
                                                directoryServices.directoryMenu?.map((value: any) => {
                                                    return (
                                                        <>
                                                            {(value.route == 'accounts') && <li key={value.title} className={activeMenu == value.title ? "pull-left ws-12 active" : "pull-left ws-12 "}>
                                                                <a className={"ws-12 "} href={"#" + value.route} onClick={() => onChangeMenu(value.title)}> {value.title} </a>
                                                            </li>}
                                                        </>
                                                    )
                                                })
                                            }

                                        </ul>
                                    </li>
                                </ul>
                            </div>

                        </div>
                    </div>

                    <div className="ws-10 ptl">
                        
                        <div className="box mtl">
                            <div className="box-header">
                                Fraudulent Listings
                            </div>
                            <div className="box-content">

                                <AreaChart className="mbl" chartPassedData={ CallChart(financialPerformance) } />

                                <BubbleChart className="mbl" chartPassedData={ CallChart(financialPerformance) } />

                                <DoughnutChart className="mbl" chartPassedData={ CallChart(financialPerformance) } />

                                <HorizontalBarChart className="mbl" chartPassedData={ CallChart(financialPerformance) } />

                                <LineChart className="mbl" chartPassedData={ CallChart(financialPerformance) } />

                                <PieChart className="mbl" chartPassedData={ CallChart(financialPerformance) } />

                                <VerticalBarChart className="mbl" chartPassedData={ CallChart(financialPerformance) } />

                            </div>

                        </div>


                    </div>

                </div>
            </div>
        </>
    )
}