import React, { useState, useEffect } from 'react';
import commonServices from "./commonServices"
import { CommonUtilities } from "../../commonUtils";
import MonitoringSlider from "./monitoringSlider";
import Pagination from "../Header/Pagination"

export default function Monitoring() {

    let [monitoringListing, setmonitoringListing] = useState<any>([]);
    let [monitoringListingFull, setmonitoringListingFull] = useState<any>([]);
    let [execListing, setexecListing] = useState<any>([]);
    let [showDownload, setShowDownload] = useState(false);
    let [totalrows, settotalrows] = useState(0);
    const [MainButton, setMainButton] = useState('Summary');
    const [SubButton, setSubButton] = useState('Basic');
    const [hideLayout, sethideLayout] = useState(false);
    const [exceLayout, setexceLayout] = useState(false);
    const [excutionStatus, setexcutionStatus] = useState(false);
    const [sqlExec, setsqlExec] = useState('');
    const [exportName, setexportName] = useState('Monitoring'); 
    let [execHeader, setexecHeader] = useState<any>([]);
    const substringsToCheck = ['drop', 'insert', 'delete' , 'update'];    
    const [openDetail, setOpenDetail] = useState(false)
    const [errorName, seterrorName] = useState('')
    const [showAdminMonitoring, setShowAdminMonitoring] = useState(false);

    const defaultListOfClient = [  
        'CherryRed_Korr','Ninja_Korr','Silva_Korr','ArtistServe_Korr','GoldenChild_Korr',
        'LNE_Korr','BellPartners_Korr','BellDistro_Korr','BELLSEK_Korr','BellGroup_Korr',
        'QaNew_korr','StagingNew_Korr'
    ]; 

    const monitoringList = (type: any, forE: any) => {
        sethideLayout(true)
        setexceLayout(false)
        commonServices.MonitorListing({ MonitoringType: type, MonitoringFor: forE }).then((values) => {

            settotalrows(values.totalRows)
            setmonitoringListingFull(CommonUtilities.relativeDataRemapping(values.data, values.headers.original));
            setmonitoringListing(CommonUtilities.relativeDataRemapping(values.data, values.headers.original).slice(0 , 10));
        })
    }

    const paginationHandlerAvailable = (n: any) => { 
        setmonitoringListing(monitoringListingFull.slice((n.pageNumber -1 ) * n.pageSize,(n.pageSize*n.pageNumber ))); 
    }

    const executionListing = (forE: any) => {        
        const sqlExecLower = sqlExec.toLowerCase();

        if (substringsToCheck.some(substring => sqlExecLower.includes(substring))) {
            console.log("error");
            setexcutionStatus(true);
            
        }else {
            setexcutionStatus(false);
            commonServices.MonitorListing({ MonitoringType: 'Execution', Execution: sqlExec }).then((values) => {
                setexecListing(CommonUtilities.relativeDataRemapping(values.data, values.headers.original))
                execHeader = [];
                Object.entries(values.headers.original).forEach(([key, value]) => { execHeader.push(value) });
                setexecHeader(execHeader) 
                console.log(CommonUtilities.relativeDataRemapping(values.data, values.headers.original))
            })
            setexceLayout(true)
            sethideLayout(false)
        }        

        setTimeout(function(){
            setexcutionStatus(false);
        }, 3000);
    }

    const executionListingExport = (forE: any) => {

        const sqlExecLower = sqlExec.toLowerCase();
        if (substringsToCheck.some(substring => sqlExecLower.includes(substring))) {
            console.log("error");
            setexcutionStatus(true);
        }else {
            console.log(sqlExec)
            commonServices.MonitorListingExport({ MonitoringType: 'Execution', Execution: sqlExec }, exportName ? exportName : '').then((values) => { })

        }

        setTimeout(function(){
            setexcutionStatus(false);
        }, 3000);
    }

    const refreshSection = (value: any) => {

        console.log(value);  
        var request  = { 
            monitoringType: 'Refresh', 
            monitoringFor: value.errorType, 
            monitoringDetail : value.errorName,
            dbname : value.dBNAME }
        console.log(request);

        commonServices.MonitorListing(request).then((response) => {
            monitoringList('Summary', 'Basic');
        })

    }

    const CloseDetail = (event: any) => {
        console.log(event)
        setOpenDetail(!event)
    }

    useEffect(() => {
        monitoringList(MainButton,"");
        
        if(window.location.href.indexOf('QA') > 0 || window.location.href.indexOf('artistserve') > 0 || window.location.href.indexOf('staging') > 0 || window.location.href.indexOf('localhost') > 0 ){

            setShowAdminMonitoring(true);

        }else{
            setShowAdminMonitoring(false);
        }
    }, []);

    const download =(event :any)=>{
        console.log(event)
        var payload = { 
            MonitoringType  : event.errorType , 
            ErrorMessage    : event.errorName, 
            MonitoringClient: event.dBNAME, 
            PageNumber      : 1, 
            PageSize        : 1000 ,
            type            : "errorListing",
            exportType      : "errorListing"
        }

        commonServices.excelGenericExport(payload);
    }
 
    return (<>

        <div className="box-header noBorderRadius bg-blue">
            <h1 style={{ textAlign: "center", color: "White" }}>Monitoring</h1>
        </div>

        <div className="box-content">
            <div className="pLarge box">

                <div className={ MainButton == 'Summary' ? "btn-innactive" : "btn-purple" } onClick={() => { setMainButton('Summary');  monitoringList('Summary', 'Basic');}}> Summary Current DB</div>
                {showAdminMonitoring && <div className={MainButton == 'SummaryALL' ? "btn-innactive" : "btn-purple"} onClick={() => { setMainButton('SummaryALL');  monitoringList('SummaryALL', 'Basic');}}> Summary ALL DB</div>}
                {showAdminMonitoring && <div className={MainButton == 'Contract' ? "btn-innactive" : "btn-purple"} onClick={() => { setMainButton('Contract'); setSubButton('Basic'); monitoringList('Contract', 'Basic'); }}> Contract</div> }
                {showAdminMonitoring && <div className={MainButton == 'metadata' ? "btn-innactive" : "btn-purple"} onClick={() => { setMainButton('metadata'); setSubButton('Basic'); monitoringList('metadata', 'Basic'); }}> metadata</div> }
                {showAdminMonitoring && <div className={MainButton == 'transaction' ? "btn-innactive" : "btn-purple"} onClick={() => { setMainButton('transaction'); setSubButton('Basic'); monitoringList('transaction', 'Basic'); }}> transaction</div> }

{/*                 <div className={MainButton == 'Project' ? "btn-innactive" : "btn-purple"} onClick={() => { setMainButton('Project'); setSubButton('Basic'); monitoringList('Project', 'Basic'); }}> Project</div>
                <div className={MainButton == 'Contact' ? "btn-innactive" : "btn-purple"} onClick={() => { setMainButton('Contact'); setSubButton('Basic'); monitoringList('Contact', 'Basic'); }}> Contact</div>
                <div className={MainButton == 'Release' ? "btn-innactive" : "btn-purple"} onClick={() => { setMainButton('Release'); setSubButton('Basic'); monitoringList('Release', 'Basic'); }}> Release</div>
                <div className={MainButton == 'Track' ? "btn-innactive" : "btn-purple"} onClick={() => { setMainButton('Track'); setSubButton('Basic'); monitoringList('Track', 'Basic'); }}> Tracks</div>
                <div className={MainButton == 'Song' ? "btn-innactive" : "btn-purple"} onClick={() => { setMainButton('Song'); setSubButton('Basic'); monitoringList('Song', 'Basic'); }}> Song</div>
                <div className={MainButton == 'Transaction' ? "btn-innactive" : "btn-purple"} onClick={() => { setMainButton('Transaction'); setSubButton('Basic'); monitoringList('Transaction', 'Basic'); }}> Transaction</div>
                <div className={MainButton == 'Report' ? "btn-innactive" : "btn-purple"} onClick={() => { setMainButton('Report'); setSubButton('Basic'); monitoringList('Report', 'Basic'); }}> Report</div> */}
            </div>

            <div className="pLarge box ptl mtl">
                {hideLayout && <div className="ws-9 pull-left box pll">
                    { false && !(MainButton == 'Summary' || MainButton == 'SummaryALL')  && <div className="pLarge ws-12">
                        <div className={SubButton == 'Basic' ? "btn-innactive" : "btn-purple"} onClick={() => { setSubButton('Basic'); monitoringList(MainButton, 'Basic'); }} > Basic</div>
                        <div className={SubButton == 'Advance' ? "btn-innactive" : "btn-purple"} onClick={() => { setSubButton('Advance'); monitoringList(MainButton, 'Advance'); }}> Advance</div>
                    </div>}

                    <div className="table-responsive mrl">
                        <table className="table mbxl">
                            <tbody>

                                {
                                    (()=>{
                                        if(MainButton == 'SummaryALL' || MainButton == 'metadata'  || MainButton == 'Contract' || MainButton == 'transaction' ){
                                            {
                                                return(
                                                    defaultListOfClient.map((valueClient:any)=>{
                                                        return (
                                                        <>
                                                            <div className="ws-12 pm pLarge">
                                                                <div className="ws-12 box-header noBorderRadius bg-level3">
                                                                    <span className="ws-10 pull-left text-bold text-center ">{valueClient}</span>
                                                                    <button type="button" className="btn-primary btn-sm pull-right hide" onClick={() => download("")}><i className="icon-download1"></i>  </button>
                                                                </div>
                                                                
                                                                { 
                                                                    monitoringListing.map((values: any) => {                                                                   

                                                                        if(values.dBNAME == valueClient){
                                                                            return (
                                                                                <>
                                                                                <tr className="ws-12">
                                                                                    <th  className="text-right ws-6">{values.errorName}</th>
                                                                                    <td  className="ws-6">
                                                                                        <div >
                                                                                            <span style={{ color: values.count > 0 ? "red" : "black"  ,height : "100%"}}
                                                                                                onClick={() => { seterrorName(values); setOpenDetail(true) }}>{values.count}</span>
                                                                                                <button type="button" className="btn-success btn-xxs pull-right" onClick={() => { seterrorName(values); setOpenDetail(true) }}>
                                                                                                    <i className="icon-eye"></i> 
                                                                                                </button>
                                                                                                <button type="button" className="btn-success btn-xxs pull-right hide"><i className="icon-edit"></i> </button> 
                                                                                                <button type="button" className="btn-primary btn-xxs pull-right" onClick={() => download(values)}><i className="icon-download1"></i>  </button>
                                                                                                <button type="button" className="btn-success btn-xxs pull-right" onClick={() => refreshSection(values)}><i className="icon-refresh"></i>  </button>
                                                                                        </div>
                                                                                    </td>
                                                                                </tr>
                                                                                </>
                                                                            )  
                                                                        }

                                                                    })
                                                                      
                                                                }
                                                                
                                                            </div>
                                                        </>)


                                                    }) 
                                                )
                                            } 
                                        }else{ 
                                            { 
                                                return(
                                                    monitoringListing.map((values: any, index : any) => {   
                                                        
                                                    return (
                                                        <>
                                                        <tr>
                                                            <th style={{ width: "25%" }} className="text-right ">{values.errorName}</th>
                                                            <td style={{ width: "25%" }} >
                                                                <div >
                                                                    <span style={{ color: values.count > 0 ? "red" : "black" }}
                                                                        onClick={() => { seterrorName(values); setOpenDetail(true) }}>{values.count}</span>
                                                                    <button type="button" className="btn-success btn-xxs pull-right" onClick={() => { seterrorName(values); setOpenDetail(true) }}>
                                                                        <i className="icon-eye"></i> 
                                                                    </button>
                                                                    <button type="button" className="btn-success btn-xxs pull-right"><i className="icon-edit"></i> </button>
                                                                    <button type="button" className="btn-primary btn-xxs pull-right" onClick={() => download(values)}><i className="icon-download1"></i>  </button>
                                                                    <button type="button" className="btn-success btn-xxs pull-right" onClick={() => refreshSection(values)}><i className="icon-refresh"></i>  </button>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                        </>
                                                    ) })
                                                )
                                            }                                            
                                        }

                                    })()
                                }                                

                            </tbody>
                            </table>
                    </div>

                    {<div className="clearfix">
                        {
                            (() => {
                                if(totalrows > 10){
                                    return <Pagination onChange={paginationHandlerAvailable} totalRows={totalrows} />
                                }                                                  
                            })()                        
                        }
                    </div>}

                </div>}

                {exceLayout && <div className="ws-9 pull-left box pll">

                    <div className="table-responsive mrl allowVscroll">

                        <table className="table mbxl mtl">
                            <tr>
                                {
                                    execHeader.map((values: any, index: any) => { return (<> <th> {values} </th> </>) })
                                }
                            </tr>

                            {
                                execListing.map((value: any) => {
                                    return (<>
                                        <tr>
                                            {
                                                execHeader.map((Hvalues: any, index: any) => {
                                                    return (<> <td> {value[Hvalues]} </td> </>)
                                                })

                                            }

                                        </tr>
                                    </>)
                                })
                            }
                        </table>
                    </div>
                </div>}

                <div className="ws-3 pull-right">

                    {excutionStatus && <div className="alert alert-success" > Avoid having 'drop', 'insert', 'delete' , 'update' in Excution </div>}
                    <div className="box mbl pbl mll ws-12">
                        <div className="mtl prl  pll mbl ws-12">
                            <textarea placeholder="Enter SQL" id="NoteDetails" className='mrl' style={{ width: "100%", height: "200px" }}
                                onChange={(e) => { setsqlExec(e.target.value); setShowDownload(false) } }></textarea></div>
                        <div className="ws-12 pm">
                            <div className="btn-innactive ws-3" onClick={() => executionListing(sqlExec)}>Execute</div>
                            <div className= {sqlExec.length > 0  ? 'btn-innactive ws-3 pull-right' : 'hide'} onClick={() => setShowDownload(!showDownload)}>Export</div>


                            <div className = { ( showDownload && sqlExec.length > 0 ) ? 'ws-12 mtl ptl' : 'hide' }>
                                <label className='ws-12 mb ml'>File Name</label>
                                <div className="ws-8"> <input className='ws-12 pLarge' onChange={e => { setexportName(e.target.value) }} value={exportName} placeholder={'Export FileName'} ></input></div>
                                <div className="btn-innactive ws-3 pull-right" onClick={() => executionListingExport(sqlExec)}>Download</div>

                            </div>





                        </div>

                    </div>

                </div>
            </div >

        </div >

        {
            (() => {
                if (openDetail) {
                    return <MonitoringSlider errorName={errorName} openDetail={openDetail} MainButton={MainButton} CloseDetail={CloseDetail} />;
                }

            })()
        }

    </>)
}