import React from "react";

import { Chart as ChartJS, ArcElement, Tooltip, Legend,CategoryScale,LinearScale,BarElement, Title } from 'chart.js/auto';

import { Line } from 'react-chartjs-2';

ChartJS.register(ArcElement, Tooltip, Legend);

export default function AreaChart(chartPassedData : any) {

  return (
    <div>
      <Line options={chartPassedData.chartPassedData.options} data={chartPassedData.chartPassedData.data}  />
    </div>
    )
}


/*import React from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-3';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend
);

export const options = {
  responsive: true,
  plugins: {
    legend: {
      position: 'top' as const,
    },
    title: {
      display: true,
      text: 'Chart.js Line Chart',
    },
  },
};

const labels = ['January', 'February', 'March', 'April', 'May', 'June', 'July'];

export const data = {
  labels,
  datasets: [
    {
      fill: true,
      label: 'Dataset 2',
      data: [15, 6, 7, 1, 8, 25, 0, 7],
      borderColor: 'rgb(53, 162, 235)',
      backgroundColor: 'rgba(53, 162, 235, 0.5)',
    }, {
      fill: true,
      label: 'Dataset 3',
      data: [2, 1, 5, 6, 7, 1, 9,13],
      backgroundColor: 'rgba(255, 206, 86, 1)',
      borderColor: 'rgba(255, 206, 86, 1)',
    }
  ],
};

export default function App() {
  return <Line options={options} data={data} />;
}

export default function App() {
  return <></>;
}*/